import React, { useEffect } from "react";

type Props = {
  id: any;
  url: string;
};

export const Player = ({ id = new Date().getTime(), url }: Props) => {
  useEffect(() => {
    let player: any;
    let timeout = setTimeout(() => {
      const base = url.split(".m3u8")[0].split("https://")[1];
      if (url.includes("tencent-live.locatraz.cn")) {
        player = (window as any).TCPlayer(`player-container-id-${id}`, {
          autoplay: true,
          multiResolution:{
            sources:{
              'SD':[
                // {
                //   src: `${url.split(".m3u8")[0]}_sd.m3u8`
                // },
                {
                  src: `webrtc://${base}_sd`
                }
              ],
              'HD':[
                // {
                //   src: url,
                // },
                {
                  src: `webrtc://${base}`
                }
              ]
            },
            // labels:{
            //   'SD':'标清','HD':'高清','FHD':'超清'
            // },
            showOrder:['SD','HD'],
            defaultRes: 'SD'
          },
          plugins: {
            ContextMenu: {
              statistic: true,
              levelSwitch: {
                open: true, 
                switchingText: "Start switching",
                switchedText: "Switch success",
                switchErrorText: "Switch fail",
              }
            }
          }
        });
      }
      else {
        player = (window as any).TCPlayer(`player-container-id-${id}`, {
          autoplay: true,
        });
        player.src(url);
      }
    }, 100);

    return () => {
      timeout && clearTimeout(timeout);
      if (player) {
        player.dispose();
      }
    };
  }, [id, url]);

  return (
    <video
      width="100%"
      height="100%"
      id={`player-container-id-${id}`}
      preload="auto"
      playsInline
    ></video>
  );
};
