import { Box, Input, Text } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";
import { useStore } from "store/RootStore";
import { sendEvent } from "util/analytic";

type Props = {
  channelId: string;
};

export const ChannelChatInput: React.FC<Props> = observer(({ channelId }) => {
  const { chatStore, authStore, uiStore } = useStore();
  const [message, setMessage] = useState("");

  const handleSubmit = useCallback(
    (event: any) => {
      event.preventDefault();
      if (!!message) {
        chatStore.sendMessageToRoom(message, channelId);
        setMessage("");
        
        sendEvent({
          category: 'chat',
          action: 'send',
          channel_id: channelId
        })
      }
    },
    [message, channelId, chatStore]
  );

  return (
    <Box padding="10px">
      {true ? (
        <form onSubmit={handleSubmit}>
          <Input
            border={"1px solid"}
            borderColor="blackAlpha.300"
            color="black"
            value={message}
            onChange={(event) => setMessage(event.target.value)}
          />
        </form>
      ) : (
        <Text display={"flex"} flexDir="row">
          <Text
            cursor={"pointer"}
            color="#3498db"
            marginRight={"5px"}
            onClick={uiStore.toggleAuthModalOpen}
          >
            Login
          </Text>
          to Chat
        </Text>
      )}
    </Box>
  );
});
