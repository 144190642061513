import { API } from "api";
import { useQuery } from "react-query";
import { IEvent } from "type";
// import moment from "moment";

export const useEvents = (channelId: string) => {
  const { data = { activeEvent: null, events: [] }, isLoading } = useQuery(
    "GetChannelEvents",
    () =>
      API.get(`/channel/${channelId}`).then((response) => ({
        activeEvent: response.data.activeEvent,
        events:
          (response.data.events || []).filter(
            (_event: IEvent) => _event.id !== response.data.activeEvent?.id
          ) ?? [],
      })),
    {
      refetchOnWindowFocus: false,
    }
  );
  return {
    data,
    isLoading,
  };
};
