import React from "react";
import { Flex, useColorModeValue } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { useStore } from "store/RootStore";
import { Navigate, Outlet } from "react-router";
import { DashboardSidebar } from "./DashboardSidebar";

export const Dashboard = observer(() => {
  const { authStore } = useStore();

  return (
    <>
      {!authStore.isLoggedIn && <Navigate to="/" />}
      <Flex w="full" h={'calc(100vh - 120px)'} flexDirection={"row"} overflow="hidden">
        <DashboardSidebar />
        <Flex
          flex={1}
          align={"center"}
          justify={"center"}
          bg={useColorModeValue("gray.50", "gray.800")}
          h="100%"
        >
          <Outlet />
        </Flex>
      </Flex>
    </>
  );
});
