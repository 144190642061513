import { IUser } from "../const/type";
import { makeAutoObservable } from "mobx";
import { AuthService } from "../service/AuthService";
import { toast } from "util/toast";
import { API } from "api";
import { isWechatBrowser } from "../util/wechat";
import { domain } from "util/domain";

const TOKEN_KEY = "buildmind_live_token";

export class AuthStore {
  user: any | null = null;
  userInfo: IUser | null = null;
  token: any;
  authLoaded: boolean = false;
  constructor() {
    makeAutoObservable(this);
    this.loadToken();
    this.init();
  }

  init = async () => {
    try {
      let token: string = this.token;
      const hash = window.location.hash;
      if (hash) {
        const hashParams = new URLSearchParams(hash.slice(1));
        if (hashParams.get("token")) {
          token = hashParams.get("token") || "";
        }
      }
      if (token) {
        this.setToken(token);
        const { data } = await AuthService.getUser();
        this.user = data;
      }
    } catch {
      this.user = null;
    } finally {
      this.authLoaded = true;
    }
  };

  login = async ({ email, password }: { email: string; password: string }) => {
    const user = await AuthService.signIn({
      email,
      password,
      domain,
    });
    if (user?.access_token) {
      this.setToken(user.access_token);
    }
    this.user = user;
    console.log(this.user);
  };

  googleLogin = async () => {
    await AuthService.signIn(
      {
        provider: "google",
      },
      {
        redirectUrl: window.location.origin,
      }
    );
  };

  wechatLogin = async (state: any = {}) => {
    await AuthService.signIn(
      {
        provider: isWechatBrowser() ? "wechat-client" : "wechat",
      },
      {
        state: JSON.stringify({
          ...state,
          redirectUrl: window.location.origin,
        }),
      }
    );
  };

  register = async ({
    email,
    name,
    password,
  }: {
    email: string;
    name: string;
    password: string;
  }) => {
    const user = await AuthService.signUp({
      email,
      name,
      password,
    });
    if (user?.access_token) {
      this.setToken(user.access_token);
    }
    this.user = user;
  };

  boardcastRegister = async ({
    email,
    name,
    password,
  }: {
    email: string;
    name: string;
    password: string;
  }) => {
    const user = await AuthService.signUpBoardCast({
      email,
      name,
      password,
    });
    return user
  }

  logout = async () => {
    await AuthService.signOut();
    this.user = null;
    this.removeToken();
  };

  loadToken = async () => {
    if (window.localStorage.getItem(TOKEN_KEY)) {
      this.token = window.localStorage.getItem(TOKEN_KEY);
    }
  };

  setToken = async (token: string) => {
    API.defaults.headers.common.Authorization = `Bearer ${token}`;
    window.localStorage.setItem(TOKEN_KEY, token);
    this.token = token;
  };

  removeToken = () => {
    window.localStorage.removeItem(TOKEN_KEY);
    this.token = null;
  };

  updateUserInfo = async (values: any) => {
    this.userInfo = {
      ...this.userInfo,
      ...values,
    };
    toast({
      title: "Profile Saved",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  get isLoggedIn(): boolean {
    return !!this.user;
  }

  get name(): string {
    return this.user?.name;
  }

  get isBroadcaster(): boolean {
    return this.user?.role.includes("broadcaster");
  }

  get isAdmin(): boolean {
    return this.user?.role.includes("admin");
  }
}

export const authStore = new AuthStore();
