import { useStore } from "store/RootStore";
import { Navigate } from "react-router";
import {
  Button,
  Grid,
  HStack,
  Input,
  Select,
  Text,
  VStack,
} from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { API } from "api";
import { domain } from "util/domain";

export const Manage = observer(() => {
  const { authStore } = useStore();
  const [user, setUser] = useState({
    email: "",
    name: "",
    password: "test",
  });
  const [channel, setChannel] = useState({
    title: "",
    screenshot: "",
    user_id: "",
    category_id: "event",
    channelID: "",
    heat: 50,
    channelPath: "/channel/",
    broadcasterName: "",
  });
  const [playback, setPlayback] = useState({
    title: "",
    channel: "",
    start_time: "2024-05-28 04:32:20",
    end_time: "2024-05-28 04:32:20",
    video_url: "",
    file_size: "",
  });

  const handleUserSignup = async () => {
    try {
      await authStore.boardcastRegister(user);
      setUser({
        email: "",
        name: "",
        password: "test",
      });
    } catch (err: any) {
      console.log(err);
    }
  };

  const handleCreateChannel = async () => {
    try {
      await API.post("/channel", {
        ...channel,
        source: "buildmind",
        is_online: false,
        domain,
        is_customized: true,
      });
      setChannel({
        title: "",
        screenshot: "",
        user_id: "",
        category_id: "event",
        channelID: "",
        heat: 50,
        channelPath: "/channel/",
        broadcasterName: "",
      });
    } catch (err: any) {
      console.log(err);
    }
  };

  const handleCreatePlayback = async () => {
    try {
      await API.post("/channel/add-custom-playback", {
        ...playback,
        file_size: parseInt(playback.file_size) * 1024 * 1024,
      });
      setPlayback({
        ...playback,
        title: "",
        video_url: "",
        file_size: "",
      });
    } catch (err: any) {
      console.log(err);
    }
  };

  const handleChannelPathChange = (event: any) => {
    //make sure the path starts with /channel/
    if (!event.target.value.startsWith("/channel/")) {
      return;
    }
    setChannel({
      ...channel,
      channelPath: event.target.value,
    });
  };

  if (!authStore.isAdmin) {
    return <Navigate to="/" />;
  }
  return (
    <VStack padding="20px" gap={2}>
      <VStack
        backgroundColor="gray.100"
        w="100%"
        rounded="md"
        padding={6}
        alignItems="flex-start"
        gap={1}
      >
        <Text>Sign up</Text>
        <HStack
          w="full"
          alignItems="center"
          justifyContent="space-between"
          gap={3}
        >
          <VStack alignItems="flex-start" flex={1}>
            <Text>Name</Text>
            <Input
              value={user.name}
              onChange={(event) =>
                setUser({
                  ...user,
                  name: event.target.value,
                  email:
                    event.target.value
                      .split(" ")
                      .map((str) => str.toLocaleLowerCase())
                      .join("_") + "@gmail.com",
                })
              }
              background="white"
            />
          </VStack>
          <VStack alignItems="flex-start" flex={1}>
            <Text>Email</Text>
            <Input
              value={user.email}
              onChange={(event) =>
                setUser({ ...user, email: event.target.value })
              }
              background="white"
            />
          </VStack>
          <VStack alignItems="flex-start" flex={1}>
            <Text>Password</Text>
            <Input
              value={user.password}
              onChange={(event) =>
                setUser({ ...user, password: event.target.value })
              }
              background="white"
            />
          </VStack>
        </HStack>
        <Button onClick={handleUserSignup} background="white">
          Sign Up Boardcaster
        </Button>
      </VStack>
      <VStack
        backgroundColor="gray.100"
        w="100%"
        rounded="md"
        padding={6}
        alignItems="flex-start"
        gap={1}
      >
        <Text>Create Channel</Text>
        <Grid
          w="full"
          alignItems="center"
          justifyContent="space-between"
          templateColumns="repeat(4, 1fr)"
          gap={6}
        >
          <VStack alignItems="flex-start" flex={1}>
            <Text>Title</Text>
            <Input
              value={channel.title}
              onChange={(event) =>
                setChannel({ ...channel, title: event.target.value })
              }
              background="white"
            />
          </VStack>
          <VStack alignItems="flex-start" flex={1}>
            <Text>Screenshot</Text>
            <Input
              value={channel.screenshot}
              onChange={(event) =>
                setChannel({ ...channel, screenshot: event.target.value })
              }
              background="white"
            />
          </VStack>
          <VStack alignItems="flex-start" flex={1}>
            <Text>User ID</Text>
            <Input
              value={channel.user_id}
              onChange={(event) =>
                setChannel({ ...channel, user_id: event.target.value })
              }
              background="white"
            />
          </VStack>
          <VStack alignItems="flex-start" flex={1}>
            <Text>Category ID</Text>
            <Select
              onChange={(event) =>
                setChannel({ ...channel, category_id: event.target.value })
              }
            >
              {["event", "playlist"].map((category) => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </Select>
          </VStack>
          <VStack alignItems="flex-start" flex={1}>
            <Text>Channel ID</Text>
            <Input
              value={channel.channelID}
              onChange={(event) =>
                setChannel({ ...channel, channelID: event.target.value })
              }
              background="white"
            />
          </VStack>
          <VStack alignItems="flex-start" flex={1}>
            <Text>Heat</Text>
            <Input
              value={channel.heat}
              type="number"
              onChange={(event) =>
                setChannel({ ...channel, heat: parseInt(event.target.value) })
              }
              background="white"
            />
          </VStack>
          <VStack alignItems="flex-start" flex={1}>
            <Text>Channel Path</Text>
            <Input
              value={channel.channelPath}
              onChange={handleChannelPathChange}
              background="white"
            />
          </VStack>
          <VStack alignItems="flex-start" flex={1}>
            <Text>Broadcaster Name</Text>
            <Input
              value={channel.broadcasterName}
              onChange={(event) =>
                setChannel({ ...channel, broadcasterName: event.target.value })
              }
              background="white"
            />
          </VStack>
        </Grid>
        <Button onClick={handleCreateChannel} background="white">
          Create Channel
        </Button>
      </VStack>
      <VStack
        backgroundColor="gray.100"
        w="100%"
        rounded="md"
        padding={6}
        alignItems="flex-start"
        gap={1}
      >
        <Text>Create Playback</Text>
        <Grid
          w="full"
          alignItems="center"
          justifyContent="space-between"
          templateColumns="repeat(4, 1fr)"
          gap={6}
        >
          <VStack alignItems="flex-start" flex={1}>
            <Text>Title</Text>
            <Input
              value={playback.title}
              onChange={(event) =>
                setPlayback({ ...playback, title: event.target.value })
              }
              background="white"
            />
          </VStack>
          <VStack alignItems="flex-start" flex={1}>
            <Text>Channel ID</Text>
            <Input
              value={playback.channel}
              onChange={(event) =>
                setPlayback({
                  ...playback,
                  channel: event.target.value,
                })
              }
              background="white"
            />
          </VStack>
          <VStack alignItems="flex-start" flex={1}>
            <Text>Video URL</Text>
            <Input
              value={playback.video_url}
              onChange={(event) =>
                setPlayback({ ...playback, video_url: event.target.value })
              }
              background="white"
            />
          </VStack>
          <VStack alignItems="flex-start" flex={1}>
            <Text>Start At</Text>
            <Input
              value={playback.start_time}
              onChange={(event) =>
                setPlayback({ ...playback, start_time: event.target.value })
              }
              background="white"
            />
          </VStack>
          <VStack alignItems="flex-start" flex={1}>
            <Text>End At</Text>
            <Input
              value={playback.end_time}
              onChange={(event) =>
                setPlayback({ ...playback, end_time: event.target.value })
              }
              background="white"
            />
          </VStack>
          <VStack alignItems="flex-start" flex={1}>
            <Text>File Size</Text>
            <Input
              value={playback.file_size}
              onChange={(event) =>
                setPlayback({ ...playback, file_size: event.target.value })
              }
              background="white"
            />
          </VStack>
        </Grid>
        <Button onClick={handleCreatePlayback} background="white">
          Create Playback
        </Button>
      </VStack>
    </VStack>
  );
});
