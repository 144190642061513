import { io } from "socket.io-client";

export const SocketIO = io(`${process.env.REACT_APP_API_ENDPOINT!}`, {
  transports: ["websocket"],
  autoConnect: false,
});

export const SocketIOEmit = (event: string, message: any) => {
  return new Promise((resolve) => {
    SocketIO.emit(event, message, (data: any) => {
      resolve(data);
    });
  });
};
