import { Box, Flex, Text } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React from "react";
import { useStore } from "store/RootStore";

type Props = {};

export const ChannelChatMessages = observer((props: Props) => {
  const { chatStore } = useStore();
  return (
    <>
      <Flex borderBottom={"1px solid"} padding="10px">
        Chat ({chatStore.numberOfOnlineViewers})
      </Flex>
      <Box padding="10px">
        {chatStore.messages.map((message, index) => (
          <Box display={"flex"} flexDir="row" key={index}>
            <Text color="gray.600">{message?.user?.name || "Guest"}</Text> :
            <Text>{message.message}</Text>
          </Box>
        ))}
      </Box>
    </>
  );
});
