import {
  FormControl,
  FormLabel,
  Input,
  // Checkbox,
  Stack,
  // Link,
  Button,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Text,
  Flex,
} from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect } from "react";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useStore } from "store/RootStore";

const useFormField = (initialValue?: any) => {
  const [value, setValue] = useState(initialValue);
  const onChange = useCallback((event: any) => {
    setValue(event?.target.value);
  }, []);
  return [value, onChange];
};

export const AuthModal = observer(() => {
  const { uiStore, authStore } = useStore();

  const [error, setError] = useState("");

  const [email, setEmail] = useFormField();
  const [name, setName] = useFormField();
  const [password, setPassword] = useFormField();

  const handleAuth = useCallback(async () => {
    try {
      if (uiStore.authModalType === "login") {
        await authStore.login({ email, password });
      } else {
        await authStore.register({ email, password, name });
      }
      uiStore.toggleAuthModalOpen();
    } catch (err: any) {
      console.log(err);
      if (err?.response?.status === 401) {
        setError("Username or Password is incorrect");
        return;
      }
      if (err?.response?.status === 403) {
        if (err?.response?.data?.message === "email.existed") {
          setError("Email is already registered.");
          return;
        }
        if (err?.response?.data?.message === "name.existed") {
          setError("Username is already registered.");
          return;
        }
      }
    }
  }, [email, password, authStore, uiStore, name]);

  useEffect(() => {
    setError("");
  }, [uiStore.authModalType]);

  return (
    <Modal
      isOpen={uiStore.isAuthModalOpen}
      onClose={uiStore.toggleAuthModalOpen}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <Stack spacing={4}>
            <FormControl id="email">
              <FormLabel>
                <FormattedMessage id="emailAddress" />
              </FormLabel>
              <Input value={email} onChange={setEmail} type="email" />
            </FormControl>
            {uiStore.authModalType === "register" && (
              <FormControl id="name">
                <FormLabel>
                  <FormattedMessage id="username" />
                </FormLabel>
                <Input value={name} onChange={setName} type="text" />
              </FormControl>
            )}
            <FormControl id="password">
              <FormLabel>
                <FormattedMessage id="password" />
              </FormLabel>
              <Input value={password} onChange={setPassword} type="password" />
            </FormControl>

            {error && <Text color="red.400">{error}</Text>}
            <Stack spacing={10}>
              {/* <Stack
                direction={{ base: "column", sm: "row" }}
                align={"start"}
                justify={"space-between"}
              >
                <Checkbox>Remember me</Checkbox>
                <Link color={"blue.400"}>Forgot password?</Link>
              </Stack> */}

              {uiStore.authModalType === "login" && (
                <Flex
                  flexDirection={"row"}
                  alignItems="center"
                  justifyContent={"space-between"}
                >
                  <Text mr="10px">
                    <FormattedMessage id="auth.noAccount" />
                  </Text>
                  <Text
                    color="#3498db"
                    onClick={() => uiStore.setAuthModalType("register")}
                  >
                    <FormattedMessage id="signup" />
                  </Text>
                </Flex>
              )}

              {uiStore.authModalType === "register" && (
                <Flex
                  flexDirection={"row"}
                  alignItems="center"
                  justifyContent={"space-between"}
                >
                  <Text mr="10px">
                    <FormattedMessage id="auth.hasAccount" />
                  </Text>
                  <Text
                    color="#3498db"
                    onClick={() => uiStore.setAuthModalType("login")}
                  >
                    <FormattedMessage id="signin" />
                  </Text>
                </Flex>
              )}
              <Button
                bg={"blue.400"}
                color={"white"}
                _hover={{
                  bg: "blue.500",
                }}
                onClick={handleAuth}
              >
                {uiStore.authModalType === "login" ? (
                  <FormattedMessage id="signin" />
                ) : (
                  <FormattedMessage id="signup" />
                )}
              </Button>
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
});
