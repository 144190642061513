import { API } from "api";

class PlaybackServiceClass {
  updatePlaybackTitle = async (playbackId: string, title: string) => {
    return API.put(`channel/playback/${playbackId} `, { title });
  };

  deletePlayback = async (playbackId: string) => {
    return API.delete(`channel/playback/${playbackId} `);
  };
}

export const PlaybackService = new PlaybackServiceClass();
