import React from "react";
import {
  Avatar,
  Button,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Stack,
  Text,
} from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { useStore } from "store/RootStore";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

type Props = {};

export const AuthAction = observer((props: Props) => {
  const { uiStore, authStore } = useStore();

  return (
    <div>
      {authStore.isLoggedIn ? (
        <Menu>
          <MenuButton
            as={Button}
            rounded={"full"}
            variant={"link"}
            cursor={"pointer"}
            minW={0}
          >
            <Stack
              textDecoration={"none"}
              direction={"row"}
              alignItems="center"
            >
              <Avatar size={"sm"} name={authStore.name} />
              <Text>{authStore.name}</Text>
            </Stack>
          </MenuButton>
          <MenuList>
            <Link to={"/dashboard/live"}>
              <MenuItem>
                <FormattedMessage id="nav.dashboard" />
              </MenuItem>
            </Link>

            {authStore.isAdmin && (
              <Link to={"/admin"}>
                <MenuItem>
                  <FormattedMessage id="nav.admin" />
                </MenuItem>
              </Link>
            )}

            {authStore.isAdmin && (
              <Link to={"/manage"}>
                <MenuItem>
                  Manage
                </MenuItem>
              </Link>
            )}
            <MenuDivider />
            <MenuItem onClick={authStore.logout}>
              <FormattedMessage id="nav.logout" />
            </MenuItem>
          </MenuList>
        </Menu>
      ) : (
        <Stack direction={"row"}>
          <Button
            onClick={() => {
              uiStore.setAuthModalType("login");
              uiStore.toggleAuthModalOpen();
            }}
            as={"a"}
            fontSize={"sm"}
            fontWeight={400}
            variant={"link"}
            href={"#"}
          >
            <FormattedMessage id="signin" />
          </Button>
          <Button
            onClick={() => {
              uiStore.setAuthModalType("register");
              uiStore.toggleAuthModalOpen();
            }}
            display={{ base: "none", md: "inline-flex" }}
            fontSize={"sm"}
            fontWeight={600}
            color={"white"}
            bg={"pink.400"}
            _hover={{
              bg: "pink.300",
            }}
          >
            <FormattedMessage id="signup" />
          </Button>
        </Stack>
      )}
    </div>
  );
});
