import { API } from "api";
import { languages } from "i18n";
import { makeAutoObservable } from "mobx";
import { IAppConfig } from "type/config";
import { domain } from "util/domain";

export class ConfigStore {
  language: "en-US" | "zh-CN" = "en-US";

  appConfig: IAppConfig | undefined;
  appConfigSchema: any;

  loaded: boolean = false;

  constructor() {
    makeAutoObservable(this);
    this.init();
  }
  init = async () => {
    try {
      await this.initLanguage();
      await this.getAppConfig();
    } catch {
    } finally {
      this.loaded = true;
    }
  };

  initLanguage = () => {
    const languageFromStorage = localStorage.getItem("language") as any;
    const languageFromBrowser = !!(languages as any)[navigator.language]
      ? navigator.language
      : "en-US";
    this.language = languageFromStorage || languageFromBrowser;
  };

  setLanguage = (language: any) => {
    this.language = language;
    localStorage.setItem("language", language);
  };

  getAppConfig = async () => {
    try {
      await API.get("/config/app", {
        params: {
          domain,
        },
      }).then((response) => {
        this.appConfig = response.data.value;
        this.appConfigSchema = response.data.schema;
      });
    } catch {}
  };

  updateAppConfig = async (config: any) => {
    try {
      await API.patch("/config/app", config);
      this.appConfig = config;
    } catch {}
  };
}

export const configStore = new ConfigStore();
