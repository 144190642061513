import React from "react";
import { Box, Container, Flex, Text } from "@chakra-ui/react";
import { ChannelCard } from "component/ChannelCard";
import { useFavoriteChannels } from "hooks/useChannel";
import { FormattedMessage } from "react-intl";

type Props = {};

export const Favorites = (props: Props) => {
  const { data: channels = [] } = useFavoriteChannels();
  return (
    <Container maxW="container.xl" display="flex" flexDir={"column"} flexGrow={1}>
      <Text fontSize="2xl" paddingY={"20px"}>
        <FormattedMessage id="pages.favourite.title" />
      </Text>

      {channels.length === 0 ? (
        <Text>
          <FormattedMessage id="pages.favourite.empty" />
        </Text>
      ) : (
        <Flex
          margin="0 auto"
          flexWrap={"wrap"}
          gap={"20px"}
          direction={"row"}
          paddingTop="50px"
          justifyContent={"space-between"}
          flexGrow={1}
        >
          {channels.map((channel, index) => (
            <ChannelCard key={channel.id} data={channel} />
          ))}
          <Box w={{ base: "45%", md: "280px" }} h="0"></Box>
          <Box w={{ base: "45%", md: "280px" }} h="0"></Box>
          <Box w={{ base: "45%", md: "280px" }} h="0"></Box>
          <Box w={{ base: "45%", md: "280px" }} h="0"></Box>
        </Flex>
      )}
    </Container>
  );
};
