import React, { ChangeEvent, useCallback, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  Button,
  Text,
  Textarea,
  Switch,
  HStack,
} from "@chakra-ui/react";
import DateTimeRangePicker from "@wojtekmaj/react-datetimerange-picker";
import { useQueryClient } from "react-query";
import { EventService } from "module/auth/service/EventService";
import { useIntl } from "react-intl";
import { sendEvent } from "util/analytic";

interface CreateEventModalProps {
  channelId: string;
  onClose: () => void;
}

const CreateEventModal: React.FC<CreateEventModalProps> = ({
  channelId,
  onClose,
}) => {
  const { formatMessage } = useIntl();
  const queryClient = useQueryClient();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [isEventStartWithStream, setIsEventStartWithStream] = useState(false);

  const handleSave = useCallback(async () => {
    await EventService.createEvent(channelId, {
      title,
      description,
      start_at: startTime,
      end_at: endTime,
      is_start_with_stream: isEventStartWithStream,
    });
    sendEvent({
      category: "add_new_event",
      action: "send",
      channel_id: channelId,
    });
    await queryClient.invalidateQueries("GetChannelEvents");
    onClose();
  }, [
    channelId,
    title,
    description,
    startTime,
    endTime,
    queryClient,
    onClose,
    isEventStartWithStream,
  ]);

  const handleDateTimeRangeClick = useCallback((dateRange: any) => {
    setStartTime(dateRange[0]);
    setEndTime(dateRange[1]);
  }, []);

  return (
    <Modal isOpen={true} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {formatMessage({ id: "dashboard.liveShow.new" })}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>{formatMessage({ id: "dashboard.liveShow.new.title" })}</Text>
          <Input
            placeholder={formatMessage({ id: "dashboard.liveShow.new.title" })}
            value={title}
            onChange={(event) => setTitle(event.target.value)}
            mb="16px"
          />
          <Text>
            {formatMessage({ id: "dashboard.liveShow.new.description" })}
          </Text>
          <Textarea
            placeholder={formatMessage({
              id: "dashboard.liveShow.new.description",
            })}
            value={description}
            onChange={(event) => setDescription(event.target.value)}
            mb="16px"
          />
          <Text>{formatMessage({ id: "dashboard.liveShow.new.time" })}</Text>
          <DateTimeRangePicker
            value={[startTime, endTime]}
            onChange={handleDateTimeRangeClick}
          />
          <HStack mt="16px">
            <Switch
              checked={isEventStartWithStream}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setIsEventStartWithStream(event.target.checked);
              }}
            />
            <Text>
              {formatMessage({ id: "dashboard.liveShow.new.startWithStream" })}
            </Text>
          </HStack>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            {formatMessage({ id: "button.cancel" })}
          </Button>
          <Button colorScheme="blue" onClick={handleSave}>
            {formatMessage({ id: "button.save" })}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateEventModal;
