import { Box } from "@chakra-ui/react";
import React from "react";
import { Footer } from "./Footer";
import { Header } from "./Header";

interface Props {
  children?: React.ReactNode;
  hasFooter?: boolean;
}

export const Layout: React.FunctionComponent<Props> = ({
  children,
  hasFooter,
}) => {
  return (
    <Box h={"100vh"} display="flex" flexDirection="column">
      <Header />
      <Box paddingTop="60px" minH={'0px'} flex={1} width="100%" display='flex' flexDirection='column'>
        {children}
        <Footer />
      </Box>
    </Box>
  );
};
