import React from "react";
import { Select } from "@chakra-ui/react";
import { LanguageOptions } from "i18n";
import { observer } from "mobx-react-lite";
import { useStore } from "store/RootStore";

type Props = {};

export const LanguageSelector = observer((props: Props) => {
  const { configStore } = useStore();
  return (
    <Select
      margin=""
      onChange={(event) => {
        configStore.setLanguage(event.target.value);
      }}
      value={configStore.language}
      width="120px"
    >
      {LanguageOptions.map((language) => (
        <option key={language.key} value={language.key}>
          {language.label}
        </option>
      ))}
    </Select>
  );
});
