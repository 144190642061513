import { API } from "api";
import { authStore } from "module/auth";

export const sendEvent = ({
  category,
  action,
  channel_id,
}: {
  category: string;
  action: string;
  channel_id: string;
}) => {
  return API.post("/analytic", {
    category,
    action,
    channel_id,
    user_id: authStore.isLoggedIn ? authStore.user?.id : undefined,
  });
};

export const sendCommonGAEvent = ({
  category,
  action,
  eventProps,
}: {
  category: string;
  action: string;
  eventProps: object;
}) => {
  return API.post("/analytic", {
    ...eventProps,
    category,
    action,
    user_id: authStore.isLoggedIn ? authStore.user?.id : undefined,
  });
};
