import { Center, Text } from "@chakra-ui/react";
import React from "react";

type Props = {};

export const NotFound = (props: Props) => {
  return (
    <Center w="full" h="full">
      <Text>404 Not Found</Text>
    </Center>
  );
};
