import { API } from "api";
import moment from "moment";
import { useQuery } from "react-query";
import { IPlayback } from "type";

export const usePlaybacks = (channelId: string) => {
  const { data = [], isLoading } = useQuery(
    "GetChannelPlaybacks",
    () =>
      API.get(`/channel/${channelId}`).then(
        (response) => (response.data.playbacks || []) as IPlayback[]
      ),
    {
      refetchOnWindowFocus: false,
    }
  );
  return {
    data:data.sort((a:IPlayback,b:IPlayback)=> moment(b.created_at).diff(moment(a.created_at))),
    isLoading,
  };
};

export const usePlaybackDetail = (playbackId: string) => {
  const { data, isLoading } = useQuery(
    "GetPlayback",
    () =>
      API.get(`/channel/playback/${playbackId}`, {
        params: {
          is_online: true,
        },
      }).then((response) => response.data as IPlayback),
    {
      refetchOnWindowFocus: false,
    }
  );
  return {
    data,
    isLoading,
  };
};
