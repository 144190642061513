import { AspectRatio, Box, Center, Flex, Image } from "@chakra-ui/react";
import { Player } from "component/Player";
import { useHeroChannels } from "hooks/useChannel";
import { useMobile } from "hooks/useMobile";
import { useMemo, useState } from "react";

export const HomeHeroPlayer = () => {
  const { isMobile } = useMobile();
  const { data: heroChannels = [] } = useHeroChannels();

  const [selectedChannel, setSelectedChannel] = useState<any>(undefined);

  const activeChannel = selectedChannel || heroChannels?.[0];

  const activeChannelRtmp = useMemo(() => {
    if (!activeChannel) {
      return "";
    } else if (!!activeChannel.video_url) {
      return activeChannel.video_url;
    } else {
      return `https://tencent-live.locatraz.cn/live/${activeChannel.username}.m3u8`;
    }
  }, [activeChannel]);

  return (
    <Flex
      borderRadius="20px"
      bg="rgba(0, 0, 0, 0.7)"
      my="20px"
      flexDir={{
        base: "column",
        md: "row",
      }}
      w="100%"
      overflow={"hidden"}
    >
      <Box
        w="100%"
        flex={1}
        minH="0"
        alignItems="center"
        justifyContent={"center"}
        display="flex"
        background={"black"}
        key={activeChannel?.id}
      >
        {activeChannel && (
          <AspectRatio key={activeChannel.id} width={"100%"} ratio={16 / 9}>
            <Player id={activeChannel.id} url={activeChannelRtmp} />
          </AspectRatio>
        )}
      </Box>
      <Flex
        px={{ base: 0, md: "10px" }}
        justifyContent={{ base: "space-between", md: "space-around" }}
        width={{ base: "auto", md: "200px" }}
        flexDir={{
          base: "row",
          md: "column",
        }}
        opacity={0.6}
        _hover={{
          opacity: 1,
        }}
      >
        {heroChannels.map((channel) => (
          <Box
            key={channel.username}
            onClick={() => {
              setSelectedChannel(channel);
            }}
            cursor={"pointer"}
            borderRadius={{ base: 0, md: "10px" }}
            overflow="hidden"
            borderWidth={"1px"}
            borderColor="transparent"
            _hover={{
              borderColor: "#3498db",
            }}
            {...(isMobile
              ? {
                  width: "33.33%",
                }
              : {})}
          >
            <AspectRatio maxW="100%" ratio={16 / 9}>
              <Image
                fallback={
                  <Center bg="gray.200" fontSize={"xl"}>
                    {channel.username}
                  </Center>
                }
                src={channel.screenshot}
                alt={`Picture of ${channel.username}`}
              />
            </AspectRatio>
          </Box>
        ))}
      </Flex>
    </Flex>
  );
};
