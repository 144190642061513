import React, { useState, useCallback } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "store/RootStore";
import { useChannelDetailByName } from "hooks/useChannel";
import {
  Box,
  Center,
  HStack,
  useColorModeValue,
  VStack,
  Image,
  AspectRatio,
  Text,
  Heading,
  IconButton,
} from "@chakra-ui/react";
import { GrEdit } from "react-icons/gr";
import { RiDeleteBin5Line } from "react-icons/ri";
import { BsPlayCircle } from "react-icons/bs";
import { IPlayback } from "type";
import { usePlaybacks } from "hooks/usePlayback";
import moment from "moment";
import EditPlayBackModal from "./EditPlayBackModal";
import DeletePlayBackModal from "./DeletePlayBackModal";
import { useIntl } from "react-intl";

export const Playback = observer(() => {
  const { formatMessage } = useIntl();
  const { authStore } = useStore();
  const bgColor = useColorModeValue("white", "gray.700");
  const { data: channel } = useChannelDetailByName(authStore.name);
  const { data: playbacks } = usePlaybacks(authStore.name);
  const [selectedPlayback, setSelectedPlayback] = useState<IPlayback>();
  const [editPlayBackNameModalOpen, setEditPlayBackNameModalOpen] =
    useState(false);
  const [deletePlayBackModalOpen, setDeletePlayBackModalOpen] = useState(false);

  const handleEditNameClick = useCallback((item: IPlayback) => {
    setSelectedPlayback(item);
    setEditPlayBackNameModalOpen(true);
  }, []);

  const handleEditPlayBackNameModalClose = useCallback(() => {
    setSelectedPlayback(undefined);
    setEditPlayBackNameModalOpen(false);
  }, []);

  const handleDeletePlaybackClick = useCallback((item: IPlayback) => {
    setSelectedPlayback(item);
    setDeletePlayBackModalOpen(true);
  }, []);

  const handleDeletePlayBackModalClose = useCallback(() => {
    setSelectedPlayback(undefined);
    setDeletePlayBackModalOpen(false);
  }, []);

  if (playbacks.length === 0) {
    return (
      <VStack
        spacing={4}
        w={"full"}
        maxW={"80%"}
        p={10}
        justifyContent="center"
      >
        <Box
          width="100%"
          bg={bgColor}
          rounded={"xl"}
          boxShadow={"lg"}
          p={10}
          alignItems="center"
        >
          <Heading as="h2" size="lg">
            {formatMessage({ id: "dashboard.playbacks.empty" })}
          </Heading>
        </Box>
      </VStack>
    );
  }

  return (
    <VStack
      spacing={4}
      w={"full"}
      maxW={"80%"}
      p={10}
      alignItems="flex-start"
      height="100%"
      overflowY="scroll"
    >
      <Heading as="h1" size="xl" textAlign="start" padding="16px 0px">
        {formatMessage({ id: "dashboard.playbacks.title" })}({playbacks.length})
      </Heading>
      {playbacks.map((playback: IPlayback) => (
        <HStack
          key={playback?.id}
          width="100%"
          bg={bgColor}
          rounded={"xl"}
          boxShadow={"lg"}
          p={10}
          cursor="pointer"
        >
          <Box w="40%" marginRight="16px">
            <AspectRatio maxW="100%" ratio={16 / 9}>
              <video>
                <source src={playback.path} type="video/mp4"></source>
              </video>
            </AspectRatio>
          </Box>
          <VStack display="flex" alignItems="flex-start">
            <Heading as="h2" size="lg">
              {formatMessage({ id: "dashboard.playbacks.name" })}:{" "}
              {playback?.title}
            </Heading>
            <Text>
              {formatMessage({ id: "dashboard.playbacks.time" })}:{" "}
              {moment(playback?.start_at).format("YYYY-MM-DD HH:mm:ss")} -{" "}
              {moment(playback?.end_at).format("YYYY-MM-DD HH:mm:ss")}
            </Text>
            <HStack>
              <a href={`/playback/${playback.id}`} rel="noreferrer">
                <IconButton
                  aria-label="play"
                  icon={<BsPlayCircle />}
                  fontSize="24px"
                  variant="ghost"
                />
              </a>
              <IconButton
                aria-label="edit"
                icon={<GrEdit />}
                fontSize="24px"
                variant="ghost"
                onClick={() => handleEditNameClick(playback)}
              />
              <IconButton
                aria-label="delete"
                icon={<RiDeleteBin5Line />}
                fontSize="24px"
                variant="ghost"
                onClick={() => handleDeletePlaybackClick(playback)}
              />
            </HStack>
          </VStack>
        </HStack>
      ))}
      {editPlayBackNameModalOpen && selectedPlayback && (
        <EditPlayBackModal
          playbackId={selectedPlayback.id}
          playbackName={selectedPlayback.title}
          onClose={handleEditPlayBackNameModalClose}
        />
      )}
      {deletePlayBackModalOpen && selectedPlayback && (
        <DeletePlayBackModal
          playbackId={selectedPlayback.id}
          playbackName={selectedPlayback.title}
          onClose={handleDeletePlayBackModalClose}
        />
      )}
    </VStack>
  );
});

export default Playback;
