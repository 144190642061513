import React, { useCallback } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
} from "@chakra-ui/react";
import { useQueryClient } from "react-query";
import { IEvent } from "type";
import { EventService } from "module/auth/service/EventService";
import { FormattedMessage, useIntl } from "react-intl";
import { sendCommonGAEvent } from "util/analytic";
interface DeleteEventModalProps {
  isActive: boolean;
  event: IEvent;
  onClose: () => void;
}

const DeleteEventModal: React.FC<DeleteEventModalProps> = ({
  isActive,
  event,
  onClose,
}) => {
  const { formatMessage } = useIntl();
  const queryClient = useQueryClient();
  const handleConfirm = useCallback(async () => {
    await EventService.deleteEvent(event.channel_id, event.id);
    sendCommonGAEvent({
      category: "delete_event",
      action: "send",
      eventProps: {
        channel_id: event.channel_id,
        event_id: event.id,
      },
    });
    await queryClient.invalidateQueries("GetChannelEvents");
    onClose();
  }, [onClose, queryClient, event]);

  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {formatMessage({ id: "delete" })}{" "}
          {isActive ? formatMessage({ id: "dashboard.liveShow.underway" }) : ""}
          {formatMessage({ id: "dashboard.liveShow.liveShow" })}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>
            <FormattedMessage
              id="dashboard.liveShow.delete.description1"
              values={{
                active: isActive
                  ? formatMessage({ id: "dashboard.liveShow.underway" })
                  : "",
                info: event.title,
              }}
            />
          </Text>
          <Text>
            {formatMessage({ id: "dashboard.liveShow.delete.description2" })}
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            {formatMessage({ id: "button.cancel" })}
          </Button>
          <Button colorScheme="blue" onClick={handleConfirm}>
            {formatMessage({ id: "button.confirm" })}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteEventModal;
