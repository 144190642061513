import { makeAutoObservable } from "mobx";

export class UiStore {
  isMobileDrawerOpen = false;

  isAuthModalOpen = false;
  authModalType: "login" | "register" = "login";

  constructor() {
    makeAutoObservable(this);
  }

  setIsMobileDrawerOpen = (bool: boolean) => {
    this.isMobileDrawerOpen = bool;
  };

  setIsAuthModalOpen = (bool: boolean) => {
    this.isAuthModalOpen = bool;
  };

  toggleAuthModalOpen = () => {
    this.isAuthModalOpen = !this.isAuthModalOpen;
  };

  setAuthModalType = (type: "login" | "register") => {
    this.authModalType = type;
  };
}

export const uiStore = new UiStore();
