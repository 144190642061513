import { Flex, Link, Text } from "@chakra-ui/react";
import { AppConfigLabel } from "component/AppConfigLabel";
import { useDomainConfig } from "hooks/useDomainConfig";
import { observer } from "mobx-react-lite";
import { Link as RouterLink } from "react-router-dom";
import { useStore } from "store/RootStore";

type Props = {};

export const Footer = observer((props: Props) => {
  const { configStore } = useStore();
  const { data: domainConfig } = useDomainConfig();
  const footerLinksLength = configStore.appConfig?.footer?.links?.length || 0;
  return (
    <Flex bg="#eee" width="100%">
      <Flex
        width="100%"
        padding="20px"
        flexDir="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Text textAlign={"center"}>{domainConfig?.config?.copyright}</Text>
        <Flex justifyContent={"center"} alignItems={"center"} flexDir={"row"}>
          {configStore.appConfig?.footer.links.map((link, index) => (
            <Link
              key={link.href}
              paddingX={"20px"}
              borderRightWidth="1px"
              borderRightColor={
                index < footerLinksLength - 1 ? "black" : "transparent"
              }
              as={RouterLink}
              to={link.href}
            >
              <AppConfigLabel label={link.label} />
            </Link>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
});
