import React, { useCallback } from "react";
import { withTheme } from "@rjsf/core";
import { Theme as ChakraUITheme } from "@rjsf/chakra-ui";
import { useStore } from "store/RootStore";
import { Navigate } from "react-router";
import { Box, Center, Spinner } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";

const uiSchema = {
  pages: {
    content: {
      "en-US": {
        "ui:widget": "textarea",
      },
      "zh-CN": {
        "ui:widget": "textarea",
      },
      "zh-HK": {
        "ui:widget": "textarea",
      },
    },
  },
};

const Form = withTheme(ChakraUITheme);

export const Admin = observer(() => {
  const { configStore, authStore } = useStore();

  const handleSubmit = useCallback(
    (value: any) => {
      configStore.updateAppConfig(value.formData);
    },
    [configStore]
  );

  if (!authStore.isAdmin) {
    return <Navigate to="/" />;
  }

  if (!configStore.appConfig) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  return (
    <Box padding="20px">
      <Form
        uiSchema={uiSchema}
        formData={configStore.appConfig}
        onSubmit={handleSubmit}
        schema={configStore.appConfigSchema}
      />
    </Box>
  );
});
