import { API } from "api";
import { domain } from "util/domain";

class AuthServiceClass {
  getUser = async () => {
    return API.get("/auth/profile");
  };

  signIn = async (data: any, options?: any) => {
    if (data.provider) {
      let href = `${process.env.REACT_APP_API_ENDPOINT}/oauth/${data.provider}`;
      if (options?.state) {
        href += `?state=${options.state}`;
      }
      window.location.href = href;
      return {};
    } else {
      const { data: user } = await API.post("/auth/login", data);
      return user;
    }
  };

  signUp = async ({
    email,
    name,
    password,
  }: {
    email: string;
    name: string;
    password: string;
  }) => {
    const { data: user } = await API.post("/auth/signup", {
      email,
      name,
      password,
      domain,
    });
    return user;
  };

  signUpBoardCast = async ({
    email,
    name,
    password,
  }: {
    email: string;
    name: string;
    password: string;
  }) => {
    const { data: user } = await API.post("/auth/signup-boardcast", {
      email,
      name,
      password,
      domain,
    });
    return user;
  };

  signOut = async () => {};
}

export const AuthService = new AuthServiceClass();
