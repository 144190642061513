import React, { useCallback, useState } from "react";
import { Box, Container, Flex, Input, Text } from "@chakra-ui/react";
import { ChannelCard } from "component/ChannelCard";
import { useChannels } from "hooks/useChannel";
import { useDebouncedValue } from "hooks/useDebouncedValue";
import { useIntl } from "react-intl";

type Props = {};

export const Search = (props: Props) => {
  const { formatMessage } = useIntl();
  const [searchName, setSearchName] = useState("");
  const debouncedSearchName = useDebouncedValue(searchName, 500);
  const { data: channels = [], isLoading } = useChannels(
    {
      name: debouncedSearchName,
      is_online: undefined,
    },
    {
      enabled: !!debouncedSearchName,
    }
  );

  const handleChange = useCallback((event: any) => {
    setSearchName(event.target.value);
  }, []);

  return (
    <Container maxW="container.xl" display="flex" flexDir={"column"} flexGrow={1}>
      <Text fontSize="2xl" paddingY={"20px"}>
        {formatMessage({ id: "pages.search.title" })}
      </Text>

      <Input
        placeholder={formatMessage({ id: "pages.search.placeholder" })}
        marginBottom={"20px"}
        onChange={handleChange}
        value={searchName}
      />

      {!!searchName && !isLoading && channels.length === 0 ? (
        <Text>{formatMessage({ id: "pages.search.noResult" })}</Text>
      ) : (
        <Flex
          margin="0 auto"
          flexWrap={"wrap"}
          gap={"20px"}
          direction={"row"}
          paddingTop="50px"
          justifyContent={"space-between"}
        >
          {channels.map((channel, index) => (
            <ChannelCard key={channel.id} data={channel} />
          ))}
          <Box w={{ base: "45%", md: "280px" }} h="0"></Box>
          <Box w={{ base: "45%", md: "280px" }} h="0"></Box>
          <Box w={{ base: "45%", md: "280px" }} h="0"></Box>
          <Box w={{ base: "45%", md: "280px" }} h="0"></Box>
        </Flex>
      )}
    </Container>
  );
};
