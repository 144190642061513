import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { useStore } from "store/RootStore";

type Props = {};

export const LiveTextSpeech = observer((props: Props) => {
  const { chatStore } = useStore();
  return (
    <Box
      padding="20px"
      maxW={"100%"}
      color="white"
      background="rgba(0,0,0,0.7)"
    >
      <Text>{chatStore.lastLiveSpeechText}</Text>
      <Text>{chatStore.liveSpeechText}</Text>
    </Box>
  );
});
