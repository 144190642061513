import React, { useCallback, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  Button,
} from "@chakra-ui/react";
import { PlaybackService } from "module/auth/service/PlaybackService";
import { useQueryClient } from "react-query";
import { useIntl } from "react-intl";
interface EditPlayBackModalProps {
  playbackId: string;
  playbackName: string;
  onClose: () => void;
}

const EditPlayBackModal: React.FC<EditPlayBackModalProps> = ({
  playbackId,
  playbackName,
  onClose,
}) => {
  const { formatMessage } = useIntl();
  const queryClient = useQueryClient();
  const [title, setTitle] = useState(playbackName);
  const handleSave = useCallback(async () => {
    await PlaybackService.updatePlaybackTitle(playbackId, title);
    await queryClient.invalidateQueries("GetChannelPlaybacks");
    onClose();
  }, [onClose, playbackId, queryClient, title]);
  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {formatMessage({ id: "dashboard.playbacks.edit" })}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Input
            placeholder={formatMessage({ id: "dashboard.playbacks.name" })}
            value={title}
            onChange={(event) => setTitle(event.target.value)}
          />
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            {formatMessage({ id: "button.cancel" })}
          </Button>
          <Button colorScheme="blue" onClick={handleSave}>
            {formatMessage({ id: "button.save" })}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditPlayBackModal;
