import React, { useEffect } from "react";
import { sendEvent } from "util/analytic";

type Props = {
  category: string;
  channelId?: string;
};

export const AnalyticEvent = ({ category, channelId }: Props) => {
  useEffect(() => {
    sendEvent({
      category,
      action: "start",
      channel_id: channelId!,
    });

    return () => {
      sendEvent({
        category,
        action: "end",
        channel_id: channelId!,
      });
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div></div>;
};


export const AnalyticEventOnMount = ({ category, channelId, action}: {
  category: string;
  action: string;
  channelId?: string;
}) => {
  useEffect(() => {
    sendEvent({
      category,
      action,
      channel_id: channelId!,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div></div>;
};