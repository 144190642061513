import { Text } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React from "react";
import { useStore } from "store/RootStore";
import { IAppConfigLabel } from "type/config";

type Props = {
  label: IAppConfigLabel;
};

export const AppConfigLabel = observer(({ label }: Props) => {
  const { configStore } = useStore();
  return <Text>{label[configStore.language!]}</Text>;
});
