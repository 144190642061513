import { Spinner, Stat, StatLabel, StatNumber } from "@chakra-ui/react";
import { API } from "api";
import React from "react";
import { useQuery } from "react-query";

type Props = {
  label: React.ReactNode;
  query: any;
  value?: number;
};

export const StatItem = ({ label, query, value }: Props) => {
  const { data = [], isLoading } = useQuery(["userStatItem", query], () => {
    return API.get("/analytic", {
      params: {
        query,
      },
    }).then((response) => response.data);
  });

  return (
    <Stat
      flex={1}
      padding="20px"
      marginX="20px"
      background="white"
      borderRadius="20px"
    >
      <StatLabel>{label}</StatLabel>
      <StatNumber>
        {isLoading ? (
          <Spinner size={"xs"} />
        ) : value ? (
          value
        ) : (
          Math.floor(data.length * 1.3)
        )}
      </StatNumber>
      {/* <StatHelpText>
              <StatArrow type="increase" />
              0%
            </StatHelpText> */}
    </Stat>
  );
};
