import { API } from "api";
import { useQuery } from "react-query";
import { useStore } from "store/RootStore";

export const useLivePushUrl = () => {
  const { authStore } = useStore();
  const { data, isLoading } = useQuery(
    ["GetLivePushUrl", authStore.user?.name],
    () =>
      API.get(`/channel/push-url/live/${authStore.user?.name}`).then(
        (response) => response.data
      ),
    {
      refetchOnWindowFocus: false,
      enabled: authStore.isBroadcaster && !!authStore.user?.name,
    }
  );
  return {
    data,
    isLoading,
  };
};
