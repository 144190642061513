import { API } from "api";

class EventServiceClass {
  createEvent = async (channelId: string, event: any) => {
    return API.post(`channel/${channelId}/events `, event);
  };

  updateEvent = async (channelId: string, eventId: string, event: any) => {
    return API.put(`channel/${channelId}/events/${eventId} `, event);
  };

  deleteEvent = async (channelId: string, eventId: string) => {
    return API.delete(`channel/${channelId}/events/${eventId} `);
  };

  setEventToActive = async (channelId: string, eventId: string) => {
    return API.patch(`channel/${channelId}`, { active_event_id: eventId });
  };
}

export const EventService = new EventServiceClass();
