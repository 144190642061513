import ReactGA from "react-ga";
import { createBrowserHistory } from "history";

ReactGA.initialize("UA-243590083-1");

export const history = createBrowserHistory();

history.listen(({ location }) => {
  ReactGA.pageview(location.pathname + location.search);
});
