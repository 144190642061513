import React from "react";
import { ChakraProvider, ThemeConfig, extendTheme } from "@chakra-ui/react";
import { QueryClientProvider } from "react-query";

import { queryClient } from "util/query";
import "./App.css";
import { RootContext, rootStore } from "store/RootStore";
import { Routes } from "Routes";

// 2. Add your color mode config
const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

// 3. extend the theme
const theme = extendTheme({ config });

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <RootContext.Provider value={rootStore}>
          <Routes />
        </RootContext.Provider>
      </ChakraProvider>
    </QueryClientProvider>
  );
}

export default App;
