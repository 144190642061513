import React, { useMemo } from "react";
import { Box } from "@chakra-ui/react";
import { Layout } from "component/Layout";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router";
import { useStore } from "store/RootStore";

type Props = {};

export const AppConfigRoutes = observer((props: Props) => {
  const { configStore } = useStore();

  const { appUrl } = useParams();

  const page = useMemo(() => {
    return configStore.appConfig?.pages.find((page) => page.link === appUrl);
  }, [appUrl, configStore.appConfig]);

  console.log(appUrl, page, page?.content);

  return (
    <Layout>
      <Box flex={1} flexGrow={1}>
        <div
          dangerouslySetInnerHTML={{
            __html: page?.content[configStore.language] || "",
          }}
          style={{ height: "calc(100vh - 130px)", width: "100%", padding: 20 }}
        ></div>
      </Box>
    </Layout>
  );
});
