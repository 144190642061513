import React from "react";
import { Center } from "@chakra-ui/layout";
import { Spinner } from "@chakra-ui/spinner";
import { observer } from "mobx-react-lite";

interface Props {}

export const Loading = observer((props: Props) => {
  return (
    <Center width="100vw" height="100vh">
      <Spinner size="xl" />
    </Center>
  );
});
