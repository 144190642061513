import { API } from "api";
import { useQuery } from "react-query";
import { domain } from "util/domain";

export const useDomainConfig = () => {
  const currentDomain = domain;
  const { data, isLoading } = useQuery(
    ["GetDomainConfig", currentDomain],
    () => {
      return API.get("/domain_config", {
        params: {
          domain: currentDomain,
        },
      }).then((response) => response.data);
    }
  );
  return {
    data,
    isLoading,
  };
};
