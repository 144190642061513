import { makeAutoObservable, reaction } from "mobx";
import { authStore } from "module/auth";
import { SocketIO, SocketIOEmit } from "util/io";

interface IMessage {
  message: string;
  user: {
    name: string;
    role: string;
    email: string;
  };
}

export class ChatStore {
  messages: IMessage[] = [];
  numberOfOnlineViewers: number = 0;

  lastLiveSpeechText: string = "";
  liveSpeechText: string = "";

  constructor() {
    makeAutoObservable(this);

    this.init();
  }

  init = () => {
    reaction(
      () => authStore.isLoggedIn,
      () => {
        if (authStore.isLoggedIn) {
          this.authentication();
        }
      }
    );
  };

  connectToRoom = async (channelId: string) => {
    SocketIO.connect();
    await this.authentication();
    await SocketIOEmit("joinRoom", {
      room: channelId,
    });
    this.removeListeners();
    this.addListeners();
  };

  disconnect = () => {
    this.removeListeners();
    SocketIO.disconnect();
    this.messages = [];
  };

  addListeners = () => {
    console.log("Add listener");
    SocketIO.on("newMessage", this.handleMessage);
    SocketIO.on("roomViewers", this.handleRoomViewers);
    SocketIO.on("liveShowSpeech", this.handleLiveShowSpeech);
  };

  removeListeners = () => {
    console.log("Remove listener");
    SocketIO.off("newMessage", this.handleMessage);
    SocketIO.off("roomViewers", this.handleRoomViewers);
    SocketIO.off("liveShowSpeech", this.handleLiveShowSpeech);
  };

  handleMessage = (data: IMessage) => {
    console.log("New Message", data);
    this.addChatMessage(data);
  };

  handleRoomViewers = (data: any) => {
    this.numberOfOnlineViewers = data.numberOfOnlineViewers;
  };

  sendMessageToRoom = (message: string, room: string) => {
    const hasToken = !!localStorage.getItem("buildmind_live_token");

    if (true) {
      SocketIO.emit("sendMessageToRoom", {
        message,
        room,
      });
    }
  };

  authentication = async () => {
    if (!!localStorage.getItem("buildmind_live_token")) {
      await SocketIOEmit("authentication", {
        token: localStorage.getItem("buildmind_live_token"),
      });
    }
  };

  addChatMessage = (message: IMessage) => {
    this.messages = [...this.messages, message];
  };

  handleLiveShowSpeech = (data: { message: string; end?: boolean }) => {
    if (data.end) {
      this.lastLiveSpeechText = `${this.liveSpeechText}.`;
    }
    this.liveSpeechText = data.message;
  };
}

export const chatStore = new ChatStore();
