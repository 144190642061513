import React from "react";
import {
  IconButton,
  Box,
  Flex,
  Icon,
  useColorModeValue,
  Link,
  Text,
  useDisclosure,
  BoxProps,
  FlexProps,
} from "@chakra-ui/react";
import {
  FiHome,
  FiTrendingUp,
  FiCompass,
  FiSettings,
  FiMenu,
} from "react-icons/fi";
import { MdEventNote, MdOndemandVideo } from "react-icons/md";
import { IconType } from "react-icons";
import { NavLink as RouterLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";

interface LinkItemProps {
  name: any;
  icon: IconType;
  href: string;
}
const LinkItems: Array<LinkItemProps> = [
  {
    name: <FormattedMessage id="dashboard.data" />,
    icon: FiHome,
    href: "stat",
  },
  {
    name: <FormattedMessage id="dashboard.liveRoommanagement" />,
    icon: FiTrendingUp,
    href: "live",
  },
  {
    name: <FormattedMessage id="dashboard.liveShow" />,
    icon: MdEventNote,
    href: "event",
  },
  {
    name: <FormattedMessage id="dashboard.playbacks" />,
    icon: MdOndemandVideo,
    href: "playback",
  },
  {
    name: <FormattedMessage id="dashboard.help" />,
    icon: FiCompass,
    href: "help",
  },
  //   { name: 'Favourites', icon: FiStar },
  // {
  //   name: <FormattedMessage id="dashboard.account" />,
  //   icon: FiSettings,
  //   href: "account",
  // },
];

export const DashboardSidebar = () => {
  const { onOpen, onClose } = useDisclosure();
  return (
    <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      />
      {/* <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full">
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer> */}
      {/* mobilenav */}
      {<MobileNav display={"none"} onOpen={onOpen} />}
    </Box>
  );
};

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  return (
    <Box
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pt="20px"
      h="full"
      {...rest}
    >
      {LinkItems.map((link) => (
        <NavItem href={link.href} key={link.name} icon={link.icon}>
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  icon: IconType;
  children: React.ReactNode;
  href: string;
}
const NavItem = ({ icon, children, href, ...rest }: NavItemProps) => {
  return (
    <RouterLink to={href}>
      {({ isActive }) => (
        <Link style={{ textDecoration: "none" }} _focus={{ boxShadow: "none" }}>
          <Flex
            marginY={"5px"}
            align="center"
            p="4"
            mx="4"
            borderRadius="lg"
            role="group"
            cursor="pointer"
            _hover={{
              bg: "cyan.400",
              color: "white",
            }}
            {...(isActive ? { bg: "cyan.400", color: "white" } : {})}
            {...rest}
          >
            {icon && (
              <Icon
                mr="4"
                fontSize="16"
                _groupHover={{
                  color: "white",
                }}
                as={icon}
              />
            )}
            {children}
          </Flex>
        </Link>
      )}
    </RouterLink>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 24 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("white", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent="flex-start"
      {...rest}
    >
      <IconButton
        variant="outline"
        onClick={onOpen}
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Text fontSize="2xl" ml="8" fontFamily="monospace" fontWeight="bold">
        Logo
      </Text>
    </Flex>
  );
};
