import { Box, Flex } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useStore } from "store/RootStore";
import { ChannelChatInput } from "./ChannelChatInput";
import { ChannelChatMessages } from "./ChannelChatMessages";

type Props = {
  channelId: string;
};

export const ChannelChat: React.FC<Props> = ({ channelId }) => {
  const { chatStore } = useStore();

  useEffect(() => {
    console.log("Mount");
    return () => {
      console.log("Unmount");
    };
  }, []);

  useEffect(() => {
    console.log("Connect to room here");
    chatStore.connectToRoom(channelId);

    return () => {
      console.log("Disconnect here");
      chatStore.disconnect();
    };

    // eslint-disable-next-line
  }, []);

  return (
    <Flex bg="white" borderRadius={"10px"} h="full" flexDirection={"column"}>
      <Flex flexDirection={"column"} flex={1}>
        <ChannelChatMessages />
      </Flex>
      <Box>
        <ChannelChatInput channelId={channelId} />
      </Box>
    </Flex>
  );
};
