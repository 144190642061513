import React from "react";
import { Container, Flex, Grid } from "@chakra-ui/react";
import { ChannelCard } from "component/ChannelCard";
import { HomeHeroPlayer } from "./HomeHeroPlayer";
import { usePlaybackChannels } from "hooks/useChannel";

export interface IChannel {
  id: string;
  screenshot: string;
  broadcasterName: string;
  is_online: boolean;
  title: string;
  category: { name: string };
  heat: number;
  channelPath: string;
}

export const Home = () => {
  const { data: channels = [], isLoading } = usePlaybackChannels();
  const gridRowItems = channels.length % 4 === 0 ? 4 : 3;

  if (isLoading) return null;

  return (
    <Flex flexDir={"column"} w="full">
      <Container maxW="container.xl">
        <HomeHeroPlayer />
      </Container>
      <Container maxW="container.xl" display="flex" justifyContent="center">
        <Grid
          margin="0 auto"
          flexWrap={"wrap"}
          gap={"20px"}
          paddingTop="50px"
          justifyContent={"space-between"}
          templateColumns={`repeat(${gridRowItems}, 1fr)`}
          marginBottom={20}
          w="100%"
        >
          {channels
            .sort((a, b) => (a.heat < b.heat ? 1 : -1))
            .map((channel: IChannel) => (
              <ChannelCard key={channel.id} data={channel} />
            ))}
        </Grid>
      </Container>
    </Flex>
  );
};
