import React, { useEffect } from "react";
import { history } from "util/history";
import { Loading } from "screen/Loading";
import {
  unstable_HistoryRouter as Router,
  Routes as SwitchRoutes,
  Route,
} from "react-router-dom";
import { useStore } from "store/RootStore";
import { Spinner, Center } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { IntlProvider } from "react-intl";
import { languages } from "i18n";
import { Home } from "screen/Home";
import { Layout } from "component/Layout";
import { ChannelDetail } from "screen/ChannelDetail";
import { Dashboard } from "screen/Dashboard";
import { PlaybackDetail } from "screen/PlaybackDetail";
import { Live } from "screen/Dashboard/Live";
import { NotFound } from "component/NotFound";
import { Help } from "screen/Help";
import { Admin } from "screen/Admin";
import { Manage } from "screen/Manage";
import { AppConfigRoutes } from "component/AppConfigRoutes";
import Event from "screen/Dashboard/Event/Event";
import Playback from "screen/Dashboard/Playback/Playback";
import { Stat } from "screen/Stat";
import { Favorites } from "screen/Favorites/Favorites";
import { Search } from "screen/Search";
import { useDomainConfig } from "hooks/useDomainConfig";

interface Props {}

export const Routes = observer((props: Props) => {
  const { authStore, configStore } = useStore();
  const { data: domainConfig } = useDomainConfig();

  useEffect(() => {
    document.title =
      domainConfig?.config?.tabName || domainConfig?.config?.title || "Live";
    // Function to update the favicon
    let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.head.appendChild(link);
    }
    link.href = domainConfig?.config?.logo;
  }, [domainConfig]);

  if (!authStore.authLoaded || !configStore.loaded) {
    return (
      <Center width="100vw" height="100vh">
        <Spinner />
      </Center>
    );
  }

  return (
    <IntlProvider
      messages={languages[configStore.language]}
      locale={configStore.language}
      defaultLocale="en-US"
    >
      <Router history={history}>
        <SwitchRoutes>
          <Route path="/loading" element={<Loading />} />
          <Route
            path="/"
            element={
              <Layout hasFooter>
                <Home />
              </Layout>
            }
          />

          <Route
            path="/search"
            element={
              <Layout>
                <Search />
              </Layout>
            }
          />

          <Route
            path="/favorites"
            element={
              <Layout>
                <Favorites />
              </Layout>
            }
          />

          <Route
            path="/channel/:channelId"
            element={
              <Layout>
                <ChannelDetail />
              </Layout>
            }
          />

          <Route
            path="/playback/:playbackId"
            element={
              <Layout>
                <PlaybackDetail />
              </Layout>
            }
          />

          <Route
            path="/admin"
            element={
              <Layout>
                <Admin />
              </Layout>
            }
          />

          <Route
            path="/manage"
            element={
              <Layout>
                <Manage />
              </Layout>
            }
          />

          <Route
            path="/dashboard"
            element={
              <Layout>
                <Dashboard />
              </Layout>
            }
          >
            <Route path="live" element={<Live />}></Route>
            <Route path="help" element={<Help />}></Route>
            <Route path="event" element={<Event />}></Route>
            <Route path="playback" element={<Playback />}></Route>
            <Route path="stat" element={<Stat />}></Route>
            <Route path="*" element={<NotFound />} />
          </Route>

          <Route path="/app/:appUrl" element={<AppConfigRoutes />}></Route>
        </SwitchRoutes>
      </Router>
    </IntlProvider>
  );
});
