import React from "react";
import {
  AspectRatio,
  Box,
  Center,
  Flex,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { Player } from "component/Player";
import { useParams } from "react-router";
import { usePlaybackDetail } from "hooks/usePlayback";
import moment from "moment";
import { AnalyticEventOnMount } from "component/AnalyticEvent";

type Props = {};

export const PlaybackDetail = (props: Props) => {
  const { playbackId } = useParams();
  const { data: playback, isLoading } = usePlaybackDetail(playbackId!);

  if (isLoading) {
    return (
      <Center>
        <Spinner size="xl" />
      </Center>
    );
  }

  if (!playback) {
    return (
      <Center>
        <Text>Playback Not Found</Text>
      </Center>
    );
  }

  return (
    <Flex background="#f5f5f5" h="100%" flexDir={{ base: "column", md: "row" }}>
      <Box
        flex={{ base: 0, md: 3 }}
        w="100%"
        h="100%"
        minH={{ base: "300px", md: "full" }}
        padding={{ base: "0px", md: "10px" }}
        flexDir={"column"}
        overflow="auto"
      >
        <Flex
          w="100%"
          height={{ base: "100%", md: "calc(100% - 70px)" }}
          flexDir={"column"}
          bg="white"
          borderRadius={"10px"}
        >
          <Text
            padding="20px"
            display={{ base: "none", md: "initial" }}
            fontSize={{ base: "15px", md: "24px" }}
            marginBottom="20px"
          >
            {`${playback.title ?? "Playback"} (${moment(
              playback.start_at
            ).format("YYYY-MM-DD HH:mm")} - ${moment(
              playback.end_at
            ).format("HH:mm")})`}
          </Text>
          <Flex
            flexDir={"column"}
            w="100%"
            height={{ base: "100%", md: "calc(100% - 60px)" }}
          >
            <Box
              w="100%"
              flex={1}
              minH="0"
              alignItems="center"
              justifyContent={"center"}
              display="flex"
              background={"black"}
            >
              <AspectRatio width={"100%"} ratio={16 / 9}>
                <Player id={playback.id} url={playback.path} />
              </AspectRatio>
              <AnalyticEventOnMount
                category="playback_view"
                action={playback.id}
                channelId={playback.channel_id}
              />
            </Box>
          </Flex>
        </Flex>
      </Box>
      <Box padding={{ base: 0, md: "10px" }} flex={1} minW="300px">
        <Center h="full" w="full" bg="white" borderRadius={"10px"}>
          No More Info
        </Center>
      </Box>
    </Flex>
  );
};
