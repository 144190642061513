import { createContext, useContext } from "react";
import { authStore, AuthStore } from "module/auth";
import { configStore, ConfigStore } from "./ConfigStore";
import { uiStore, UiStore } from "./UiStore";
import { chatStore, ChatStore } from "./ChatStore";

export interface RootStore {
  authStore: AuthStore;
  configStore: ConfigStore;
  uiStore: UiStore;
  chatStore: ChatStore;
}

export const rootStore: RootStore = {
  authStore,
  configStore,
  uiStore,
  chatStore,
};

export const RootContext = createContext<RootStore>(rootStore);

export const useStore = (): RootStore => {
  const store = useContext(RootContext);
  return store;
};

(window as any).rootStore = rootStore;
