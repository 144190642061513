import React, { useCallback, useEffect, useMemo } from "react";
import {
  AspectRatio,
  Box,
  Button,
  Center,
  Flex,
  Link,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ChannelChat } from "component/ChannelChat";
import { Player } from "component/Player";
import { useParams } from "react-router";
import { useChannelDetailByName, useFavoriteChannels } from "hooks/useChannel";
import moment from "moment";
import { Link as RouterLink } from "react-router-dom";
import { API } from "api";
import { AnalyticEvent } from "component/AnalyticEvent";
import { LiveTextSpeech } from "component/LiveTextSpeech";
import { FormattedMessage } from "react-intl";

type Props = {};

export const ChannelDetail = (props: Props) => {
  const [showVideoPlayer, setShowVideoPlayer] = React.useState<boolean>(false);
  const { channelId } = useParams();
  const { data: channel, isLoading } = useChannelDetailByName(channelId!);
  const { data: favoriteChannels = [], refetch } = useFavoriteChannels();

  const isFavorite = useMemo(() => {
    return favoriteChannels.find(
      (favoriteChannel) => channel?.id === favoriteChannel.id
    );
  }, [favoriteChannels, channel]);

  const {
    isActiveEventNotBegin,
    activeEventStart,
    activeEventEnd,
    activeEventTitle,
    activeEventDetail,
  } = useMemo(() => {
    if (!channel || !channel.activeEvent)
      return {
        isActiveEventNotBegin: false,
        activeEventStart: null,
        activeEventEnd: null,
        activeEventDetail: "",
      };
    const { activeEvent } = channel;
    return {
      isActiveEventNotBegin: moment(activeEvent.start_at).isAfter(moment()),
      activeEventStart: moment(activeEvent.start_at).format("YYYY-MM-DD HH:mm"),
      activeEventEnd: moment(activeEvent.end_at).format("YYYY-MM-DD HH:mm"),
      activeEventTitle: activeEvent.title,
      activeEventDetail: activeEvent.description,
    };
  }, [channel]);

  const channelRtmp = useMemo(() => {
    if (!channel) {
      return "";
    } else if (!!channel.rtmp) {
      return channel.rtmp;
    } else {
      return `https://tencent-live.locatraz.cn/live/${channel?.user?.name}.m3u8`;
    }
  }, [channel]);

  const checkIsReadyToLive = useCallback(() => {
    const { is_online, activeEvent } = channel || {};
    if (activeEvent) {
      if (activeEvent?.is_start_with_stream) {
        if (is_online && !!channelRtmp) {
          setShowVideoPlayer(true);
        }
      } else {
        if (
          is_online &&
          !!channelRtmp &&
          !moment(activeEvent.start_at).isAfter(moment())
        ) {
          setShowVideoPlayer(true);
        }
      }
    } else {
      if (is_online && !!channelRtmp) {
        setShowVideoPlayer(true);
      }
    }
  }, [channel, channelRtmp]);

  useEffect(() => {
    const { activeEvent } = channel || {};
    if (activeEvent && moment(activeEvent.start_at).isAfter(moment())) {
      setInterval(() => {
        checkIsReadyToLive();
      }, 5000);
    }
    checkIsReadyToLive();
  }, [channel, channelRtmp, checkIsReadyToLive]);

  const hasPlaybacks = useMemo(() => {
    return (channel?.playbacks?.length || 0) > 0;
  }, [channel]);

  const onClickFavorite = useCallback(async () => {
    try {
      if (isFavorite) {
        await API.delete(`/channel/favorites/${channel?.id}`);
      } else {
        await API.post(`/channel/favorites/${channel?.id}`);
      }
    } catch {
    } finally {
      refetch();
    }
  }, [channel, refetch, isFavorite]);

  if (isLoading) {
    return (
      <Center>
        <Spinner size="xl" />
      </Center>
    );
  }

  if (!channel) {
    return (
      <Center>
        <Text>
          <FormattedMessage id="pages.channel.not.found" />
        </Text>
      </Center>
    );
  }

  return (
    <Flex background="#f5f5f5" h="100%" flexDir={{ base: "column", md: "row" }}>
      <Box
        flex={{ base: 1, md: 3 }}
        w="100%"
        h="100%"
        minH={{ base: "200px", md: "full" }}
        padding={{ base: "0px", md: "10px" }}
        flexDir={"column"}
        overflow="auto"
      >
        <Flex
          w="100%"
          bg="white"
          padding="20px"
          borderRadius={"10px"}
          mb="10px"
          alignItems={"center"}
        >
          <Text
            display={{ base: "none", md: "initial" }}
            fontSize={{ base: "15px", md: "30px" }}
          >
            {channel?.title || <FormattedMessage id="pages.channel.name" />}
          </Text>
          <Box flex={1}></Box>
          <Button onClick={onClickFavorite}>
            <FormattedMessage
              id={
                isFavorite
                  ? "pages.channel.cancel.favorite"
                  : "pages.channel.favorite"
              }
            />
          </Button>
        </Flex>
        {showVideoPlayer ? (
          <Flex
            w="100%"
            minHeight={{ base: "100%", md: "calc(100% - 200px)" }}
            flexDir={"column"}
            bg="white"
            borderRadius={"10px"}
            flex={1}
          >
            <Flex
              flexDir={"column"}
              w="100%"
              height={{ base: "100%", md: "calc(100% - 60px)" }}
            >
              <Box
                w="100%"
                overflow={"hidden"}
                flex={1}
                minH="0"
                alignItems="center"
                justifyContent={"center"}
                display="flex"
                background={"black"}
                position="relative"
              >
                <AspectRatio height={"100%"} width={"100%"} ratio={16 / 9}>
                  <Player id={channelId} url={channelRtmp} />
                </AspectRatio>
                <AnalyticEvent category="channel_view" channelId={channel.id} />

                <Box position={"absolute"} width="50%" bottom="20px" left="25%">
                  <LiveTextSpeech />
                </Box>
              </Box>
            </Flex>

            <Box padding="10px">
              <Text
                marginBottom={"10px"}
                fontSize={{ base: "16px", md: "24px" }}
              >
                {activeEventTitle} ({activeEventStart} - {activeEventEnd})
              </Text>
              <Text fontSize={{ base: "12px", md: "18px" }}>
                {activeEventDetail}
              </Text>
            </Box>
          </Flex>
        ) : (
          <Flex height="calc(100% - 200px)">
            {isActiveEventNotBegin ? (
              <Box
                w="100%"
                alignItems="center"
                justifyContent={"center"}
                display="flex"
                background={"black"}
                padding="20px"
              >
                <Center flexDir={"column"}>
                  <Text
                    marginBottom={"10px"}
                    color={"white"}
                    fontSize={{ base: "20px", md: "36px" }}
                  >
                    {activeEventDetail}
                  </Text>
                  <Text color={"white"} fontSize={{ base: "12px", md: "18px" }}>
                    <FormattedMessage id="pages.channel.start.time" />:{" "}
                    {channelId === "the_one"
                      ? "2024-11"
                      : `${activeEventStart} - ${activeEventEnd}`}
                  </Text>
                </Center>
              </Box>
            ) : (
              <Box
                w="100%"
                alignItems="center"
                justifyContent={"center"}
                display="flex"
                background={"black"}
                padding="20px"
              >
                <Center>
                  <Text color="white" fontSize={{ base: "20px", md: "32px" }}>
                    {channel?.title}
                  </Text>
                </Center>
              </Box>
            )}
          </Flex>
        )}

        {!hasPlaybacks && (
          <Flex
            mt="20px"
            padding="20px"
            flexDir={"column"}
            w="100%"
            bg="white"
            borderRadius={"10px"}
            flex={1}
          >
            <Text padding="20px">
              <FormattedMessage id="pages.channel.no.playback" />
            </Text>
          </Flex>
        )}

        {hasPlaybacks && (
          <Flex
            mt="20px"
            flexDir={"column"}
            w="100%"
            bg="white"
            borderRadius={"10px"}
            flex={1}
          >
            <Text
              padding="20px 20px 10px"
              fontSize={{ base: "16px", md: "22px" }}
            >
              <FormattedMessage id="pages.channel.playbacks" />
            </Text>
            {showVideoPlayer ? (
              <Box w="100%" overflowX="auto" padding="0px 20px 20px">
                <Flex flexDir={"row"}>
                  {channel.playbacks
                    .sort((a, b) =>
                      moment(a.start_at).isBefore(moment(b.start_at)) ? -1 : 1
                    )
                    .map((playback) => (
                      <Link
                        to={`/playback/${playback.id}`}
                        as={RouterLink}
                        flexDir={"column"}
                        minW="150px"
                        key={playback.id}
                        borderRadius={"10px"}
                        marginRight="20px"
                        cursor={"pointer"}
                      >
                        <AspectRatio width={"100%"} ratio={16 / 9}>
                          <video
                            width="400"
                            controls={false}
                            preload="metadata"
                          >
                            <source src={playback.path} type="video/mp4" />
                          </video>
                        </AspectRatio>
                        <Text>
                          <FormattedMessage id="pages.channel.playback" />{" "}
                          {moment(playback.start_at).format("YYYY-MM-DD HH:mm")}
                        </Text>
                      </Link>
                    ))}
                </Flex>
              </Box>
            ) : (
              <Box h="100%" width="100%" overflowY="auto">
                <Flex flexDir={"column"} padding="20px">
                  {channel.playbacks
                    .sort((a, b) =>
                      moment(a.start_at).isBefore(moment(b.start_at)) ? 1 : -1
                    )
                    .map((playback) => (
                      <Link
                        to={`/playback/${playback.id}`}
                        as={RouterLink}
                        flexDir={"row"}
                        width="100%"
                        key={playback.id}
                        borderRadius={"10px"}
                        marginRight="20px"
                        cursor={"pointer"}
                        alignItems="center"
                        display="flex"
                        mb="16px"
                        boxShadow="0 0 10px 0 rgba(0,0,0,0.1)"
                        padding="8px"
                      >
                        <AspectRatio width={"40%"} ratio={16 / 9} mr="16px">
                          <video
                            width="400"
                            controls={false}
                            preload="metadata"
                          >
                            <source src={playback.path} type="video/mp4" />
                          </video>
                        </AspectRatio>
                        <VStack>
                          <Text>
                            {playback?.title}{" "}
                            {moment(playback.start_at).format(
                              "YYYY-MM-DD HH:mm"
                            )}
                          </Text>
                          <Text></Text>
                        </VStack>
                      </Link>
                    ))}
                </Flex>
              </Box>
            )}
          </Flex>
        )}
      </Box>
      <Box padding={{ base: 0, md: "10px" }} flex={1} minW="300px">
        <ChannelChat channelId={channelId!} />
      </Box>
    </Flex>
  );
};
