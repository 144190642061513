import React, { useMemo, useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  useColorModeValue,
  Center,
  Text,
  HStack,
  // Avatar,
  // AvatarBadge,
  // IconButton,
} from "@chakra-ui/react";
import { useLivePushUrl } from "hooks/useLive";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { observer } from "mobx-react-lite";
import { useStore } from "store/RootStore";
import { useChannelDetailByName } from "hooks/useChannel";
import { ChannelService } from "module/auth/service/ChannelService";
import { useQueryClient } from "react-query";
import { FormattedMessage } from "react-intl";
import { sendEvent } from "util/analytic";
import { useDomainConfig } from "hooks/useDomainConfig";

export const Live = observer(() => {
  const { authStore } = useStore();
  const { data: livePush = {} } = useLivePushUrl();
  const { data: channel } = useChannelDetailByName(authStore.user?.name);
  const queryClient = useQueryClient();
  const { data: domainConfig } = useDomainConfig();

  const [channelTitle, setChannelTitle] = useState(channel?.title || "");

  const handleChannelTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChannelTitle(e.target.value);
  };

  const isChannelTitleChanged = useMemo(() => {
    if (!channel) return false;
    if (!channel.title && channelTitle === "") return false;
    return channelTitle !== channel.title;
  }, [channelTitle, channel]);

  const handleSaveChannelTitle = async () => {
    if (channel) {
      try {
        await ChannelService.updateChannel(channel.id, { title: channelTitle });
        sendEvent({
          category: "update_channel_title",
          action: "send",
          channel_id: channel.id,
        });
        await queryClient.invalidateQueries("GetChannelByName");
      } catch {}
    }
  };

  return (
    <>
      <Stack
        spacing={4}
        w={"full"}
        maxW={"lg"}
        bg={useColorModeValue("white", "gray.700")}
        rounded={"xl"}
        boxShadow={"lg"}
        p={10}
        my={12}
      >
        <HStack justifyContent="space-between">
          <Heading lineHeight={1.1} fontSize={{ base: "2xl", sm: "3xl" }}>
            <FormattedMessage id="dashboard.liveRoommanagement" />
          </Heading>
          {isChannelTitleChanged && (
            <Button onClick={handleSaveChannelTitle}>
              <FormattedMessage id="button.save" />
            </Button>
          )}
        </HStack>
        {/* <FormControl id="userName">
          <FormLabel>User Icon</FormLabel>
          <Stack direction={["column", "row"]} spacing={6}>
            <Center>
              <Avatar size="xl" src="https://bit.ly/sage-adebayo">
                <AvatarBadge
                  as={IconButton}
                  size="sm"
                  rounded="full"
                  top="-10px"
                  colorScheme="red"
                  aria-label="remove Image"
                  icon={<SmallCloseIcon />}
                />
              </Avatar>
            </Center>
            <Center w="full">
              <Button w="full">Change Icon</Button>
            </Center>
          </Stack>
        </FormControl> */}

        {authStore.isBroadcaster ? (
          <>
            <FormControl id="userName" isRequired>
              <FormLabel>
                <FormattedMessage id="dashboard.liveRoommanagement.title" />
              </FormLabel>
              <InputGroup>
                <Input
                  value={channelTitle}
                  onChange={handleChannelTitleChange}
                  type="text"
                />
              </InputGroup>
            </FormControl>
            <FormControl id="userName" isRequired>
              <FormLabel>
                <FormattedMessage id="dashboard.liveRoommanagement.server" />
              </FormLabel>
              <InputGroup>
                <Input value={livePush.server} disabled type="text" />
                <InputRightElement width="4.5rem">
                  <CopyToClipboard text={livePush.server}>
                    <Button h="1.75rem" size="sm">
                      <FormattedMessage id="button.copy" />
                    </Button>
                  </CopyToClipboard>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <FormControl id="email" isRequired>
              <FormLabel>
                <FormattedMessage id="dashboard.liveRoommanagement.key" />
              </FormLabel>
              <InputGroup>
                <Input value={livePush.key} disabled type="text" />
                <InputRightElement width="4.5rem">
                  <CopyToClipboard text={livePush.key}>
                    <Button h="1.75rem" size="sm">
                      <FormattedMessage id="button.copy" />
                    </Button>
                  </CopyToClipboard>
                </InputRightElement>
              </InputGroup>
            </FormControl>
          </>
        ) : (
          <Center my="20px" flexDir={"column"} alignItems="center">
            <Text fontSize={"lg"} fontWeight="bold" mb="10px">
              <FormattedMessage id="dashboard.liveRoommanagement.notAnchor" />
            </Text>
            <Text>
              <FormattedMessage id="dashboard.liveRoommanagement.becomeAnchor" />
            </Text>
            <Text>
              <FormattedMessage
                id="dashboard.liveRoommanagement.contact"
                values={{
                  email: domainConfig?.config?.helpEmail,
                }}
              />
            </Text>
          </Center>
        )}
      </Stack>
    </>
  );
});
